// Review folling pages //
https://stackoverflow.com/questions/37534249/nested-arrays-of-objects-and-v-for
// https://jsfiddle.net/RomainMazB/3L9uhrxe/ //
https://stackoverflow.com/questions/61943722/vue-v-for-how-to-iterate-two-dimensional-array
//
https://stackoverflow.com/questions/46219355/vuejs-v-for-multidimensional-array-json-object
<template>
  <div>
    <div id="step-list" class="inner">
      <div id="intro">
        <h2>Choose your path</h2>
        <div id="step-number-container">
          <p>
            Step
            <span v-if="currentStep === 1">1</span>
            <span v-else-if="currentStep >= 2 && !programStep">2</span>
            <span v-else-if="!!programStep && currentStep <= 6">3</span> of 3
          </p>
        </div>
        <p class="stepped-heading one" v-if="currentStep === 1">
          What type of degree or certificate do you want to earn?
        </p>
        <p
          class="stepped-heading two"
          v-else-if="currentStep >= 2 && !programStep"
        >
          What’s your academic interest?
        </p>
        <p
          class="stepped-heading three"
          v-else-if="!!programStep && currentStep <= 6"
        >
          What’s your career focus?
        </p>
      </div>
      <div v-if="currentStep === 1" id="step1">
        <div class="flex-container">
          <div class="flex" id="school-image">
            <img loading="lazy" :src="bannerPicture" alt="Academic Pathways" />
          </div>
          <div class="flex" id="school-level">
            <p class="degree-selection" @click="nextStep(2)">
              <span class="animate-underline-l-to-r">Undergraduate</span>
            </p>
            <p class="degree-selection" @click="nextStep(3)">
              <span class="animate-underline-l-to-r">Graduate</span>
            </p>
            <p class="degree-selection" @click="nextStep(4)">
              <span class="animate-underline-l-to-r">Certificate</span>
            </p>
            <p class="degree-selection" @click="nextStep(5)">
              <span class="animate-underline-l-to-r">Dual Degree</span>
            </p>
          </div>
        </div>
      </div>
      <!-- <div v-if="currentStep === 1">
      <div class="flex-container">
        <div class="flex">
          <img @click="nextStep(2)" src="img/compressed/Thumb-image.jpg" />
          <p><a @click="nextStep(2)">Undergraduate</a></p>
        </div>
        <div class="flex">
          <img @click="nextStep(3)" src="img/compressed/Thumb-image-1.jpg" />
          <p><a @click="nextStep(3)">Graduate</a></p>
        </div>
        <div class="flex">
          <img @click="nextStep(4)" src="img/compressed/Thumb-image-2.jpg" />
          <p><a @click="nextStep(4)">Certificate</a></p>
        </div>
        <div class="flex">
          <img @click="nextStep(5)" src="img/compressed/Thumb-image-3.jpg" />
          <p><a @click="nextStep(5)">Dual Degrees</a></p>
        </div>
      </div>
    </div> -->

      <div v-if="currentStep >= 2 && !programStep" class="undergrad-grad">
        <!-- <h1>{{ academicGroup[currentStep - 2].title }}</h1> -->
        <div class="button-container">
          <button
            v-for="(program, index) in academicGroup[currentStep - 2]
              .programList"
            :key="index"
            @click="
              nextProgramStep(program.name);
              nextProgramDescription(program.description);
              nextProgramLink(program.schoolLink);
              nextLearnMoreSection(program.additionalInfo);
              nextIndividualLink(index);
            "
          >
            {{ program.name }}

            <!-- {{ department.departmentDescription }}
      {{ department.departmentUrl }}: {{ index }} -->
          </button>
        </div>
        <p class="start-over-container" style="text-align: left">
          <a class="start-over" @click="nextStep(1)">Start over</a>
        </p>
      </div>
      <div v-if="!!programStep" class="school-level artscomm">
        <div class="school-info">
          <div class="academic-interest-top">
            <h3 class="school-title">
              {{ programStep }}
            </h3>
            <p>
              {{ programDescription }}
            </p>
          </div>
          <div class="menu-options">
            <h3 v-if="programLink" class="school-title">Learn More About</h3>
            <p v-if="programLink">
              <a style="text-decoration: underline" :href="programLink">{{
                programStep
              }}</a>
            </p>
            <p v-html="additionalInfo"></p>
            <p class="go-back">
              <a class="back-to-categories" @click="programClear()"
                >Back to categories</a
              >
            </p>

            <p class="go-back go-back-second">
              <a
                class="start-over"
                @click="
                  nextStep(1);
                  programClear();
                "
                >Start over</a
              >
            </p>
          </div>
        </div>
        <div class="degree-columns">
          <!-- {{ index }}
          <h3>{{ department.name }}</h3> -->
          <div
            v-for="(individual, index) in academicGroup[currentStep - 2]
              .programList[individualStep].departments"
            :key="index"
            class="degree-box"
          >
            <h3>{{ individual.departmentName }}</h3>
            <p>{{ individual.departmentDescription }}</p>
            <span v-if="currentStep === 1">1</span>
            <span v-else-if="currentStep >= 2 && !programStep">2</span>
            <a
              v-if="individual.departmentProgramUrl"
              :href="individual.departmentProgramUrl"
              >View Program Page</a
            >
            <a v-else :href="individual.departmentUrl">View Department Page</a>
          </div>
          <!-- <p>{{ department.departmentDescription }}</p>
          <a :href="department.departmentUrl">View Department Page</a> -->
        </div>
        <!-- <div class="degree-columns">
        <div
          v-for="(department, index) in academicGroup[currentStep - 2]
            .programList[0].departments"
          :key="index"
          class="degree-box"
        >
          {{ index }}
          <h3>{{ department.departmentName }}</h3>
          <p>{{ department.departmentDescription }}</p>
          <a :href="department.departmentUrl">View Department Page</a>
        </div>
      </div> -->
      </div>
    </div>
    <div v-if="currentStep === 1" class="full-width yellow-background">
      <div class="inner-small" id="academic-description">
        <h3>Or explore by school</h3>

        <ul class="clear" id="explore-list">
          <li>
            <a href="https://artscomm.tcnj.edu/"
              >ARTS AND
              <br class="mobile-breaks-condensed-small" />COMMUNICATION</a
            ><span class="last-character"></span>
          </li>
          <br class="mobile-breaks" />
          <li>
            <a href="https://business.tcnj.edu/">BUSINESS</a
            ><span class="last-character"></span>
          </li>
          <li>
            <a href="https://education.tcnj.edu/">EDUCATION</a
            ><span class="last-character"></span>
          </li>
          <br class="mobile-breaks-condensed" />
          <li>
            <a href="https://engineering.tcnj.edu/">ENGINEERING</a
            ><span class="last-character"></span>
          </li>
          <br class="mobile-breaks" />

          <li>
            <a href="https://hss.tcnj.edu/"
              >HUMANITIES AND <br class="mobile-breaks-condensed-small" />SOCIAL
              SCIENCES</a
            ><span class="last-character"></span>
          </li>
          <br class="mobile-breaks" />

          <li>
            <a href="https://nhs.tcnj.edu/"
              >NURSING <br class="mobile-breaks-condensed" />AND HEALTH
              SCIENCES</a
            ><span class="last-character"></span>
          </li>
          <br class="mobile-breaks" />

          <li>
            <a href="https://science.tcnj.edu">SCIENCE</a
            ><span class="last-character" id="last-one"></span>
          </li>
        </ul>
        <div class="flex-center-container">
          <a class="button-link blue clear" href="https://academics.tcnj.edu/"
            >More about Academics</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AcademicPathwaysFinal",
  data() {
    return {
      currentStep: 1,
      programStep: "",
      programDescription: "",
      programLink: "",
      programLearnMore: "",
      individualStep: 0,
      bannerPicture:
        "https://tcnj.edu/custom/homepage/img/pathway-image-23-final-2.jpg",
      academicGroup: [
        {
          title: "Undergraduate",
          programList: [
            {
              name: "Arts and Communication",
              schoolLink: "https://artscomm.tcnj.edu/",
              additionalInfo:
                "<a style='text-decoration:underline' href='https://artscomm.tcnj.edu/academics/teacher-education-programs/'>Teacher Education Programs</a>",
              description:
                "Meet us at the intersection of creativity, drive, innovation, and technology, where new ideas originate that embrace the present while respecting the past.",

              departments: [
                {
                  departmentName: "Art and Art History",
                  departmentDescription:
                    "Develop conceptual, technical, and intellectual abilities while preparing for an arts career.",
                  departmentUrl: "https://art.tcnj.edu/",
                  departmentProgramUrl: "",
                },
                {
                  departmentName: "Communication Studies",
                  departmentDescription:
                    "Match message to medium — integrating conceptual skills, practical experience, and critical thinking.",
                  departmentUrl: "https://communicationstudies.tcnj.edu/",
                  departmentProgramUrl: "",
                },
                {
                  departmentName: "Graphic Design",
                  departmentDescription:
                    "Gain the creative, strategic, and technical skills for a successful career in visual communication.",
                  departmentUrl: "https://dct.tcnj.edu/",
                  departmentProgramUrl: "",
                },
                {
                  departmentName: "Fine Art",
                  departmentDescription:
                    "Develop the skills and knowledge to create compelling images, objects, and experiences.",
                  departmentUrl: "https://art.tcnj.edu/",
                  departmentProgramUrl: "",
                },
                {
                  departmentName: "Interactive Multimedia",
                  departmentDescription:
                    "Engage your breadth of talents in creating projects that entertain, empower, and educate.",
                  departmentUrl: "https://dct.tcnj.edu/",
                  departmentProgramUrl: "",
                },
                {
                  departmentName: "Journalism and Professional Writing",
                  departmentDescription:
                    "Craft a future around your passion for writing — honing creative and practical skills via campus media.",
                  departmentUrl: "https://journalism.tcnj.edu/",
                  departmentProgramUrl: "",
                },
                {
                  departmentName: "Music",
                  departmentDescription:
                    "Broaden your musicianship with profound academic inquiry and extensive performance opportunities.",
                  departmentUrl: "https://music.tcnj.edu/",
                  departmentProgramUrl: "",
                },
                {
                  departmentName: "Photo and Video Art",
                  departmentDescription:
                    "Explore technical and conceptual aspects of storytelling via digital and traditional practices.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://art.tcnj.edu/academics/bfa-majors/photo-and-video-specialization/",
                },
                {
                  departmentName:
                    "Undeclared–School of the Arts and Communication",
                  departmentDescription:
                    "Follow your interests to explore and define your future specialty in your first year.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://artscomm.tcnj.edu/academics/undeclared/",
                },
              ],
            },
            {
              name: "Business",
              schoolLink: "https://business.tcnj.edu/",
              additionalInfo:
                "<a style='text-decoration:underline' href='https://business.tcnj.edu/academics/teacher-education-program/'>Teacher Education Program in Economics</a>",
              description:
                "Get equipped for success in a community of innovators that prioritizes discovery, agility, analysis, and imagination. ",
              departments: [
                {
                  departmentName: "Accounting and Information Systems",
                  departmentDescription:
                    "Learn about accounting and information systems to evaluate and resolve complex problems.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://business.tcnj.edu/academics/acct/",
                },
                {
                  departmentName: "Economics",
                  departmentDescription:
                    "Study the policies and activities that govern the way resources are allocated in society.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://business.tcnj.edu/academics/economics/",
                },
                {
                  departmentName: "Finance",
                  departmentDescription:
                    "Examine how strategic resource allocation can increase value for an individual or organization.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://business.tcnj.edu/academics/finance/",
                },
                {
                  departmentName: "Global Business",
                  departmentDescription:
                    "Global business combines a business major with an international studies major or minor.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://business.tcnj.edu/academics/global-business/",
                },
                {
                  departmentName: "Marketing and Interdisciplinary Business",
                  departmentDescription:
                    "Specialize in creating consumer relationships or connecting business acumen to other passions.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://business.tcnj.edu/academics/marketing-and-idb/",
                },
                {
                  departmentName: "Management",
                  departmentDescription:
                    "Study the art of motivating workplace performance and leading organizations to greater prosperity.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://business.tcnj.edu/academics/management/",
                },
                {
                  departmentName: "Undeclared–School of Business",
                  departmentDescription:
                    "Follow your interests to explore and define your future business specialty in your first year.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://business.tcnj.edu/departments-programs/undeclared/",
                },
              ],
            },
            {
              name: "Education",
              schoolLink: "https://education.tcnj.edu/",
              additionalInfo:
                "<a style='text-decoration:underline; margin-bottom: 20px; display: block;' href='https://education.tcnj.edu/academics/five-year-programs/'>Five-Year Programs</a><a style='text-decoration:underline' href='https://education.tcnj.edu/careers-and-networking/student-teaching/'>Student Teaching</a>",
              description:
                "Become an educator and experience the profound joy — and powerful opportunity — in connecting learners with their own potential to learn and grow.",
              departments: [
                {
                  departmentName: "Early Childhood Education",
                  departmentDescription:
                    "Gain the training, education, and fieldwork experience to instruct early learners.",
                  departmentUrl: "https://eeced.tcnj.edu/",
                  departmentProgramUrl: "",
                },
                {
                  departmentName: "Education of the Deaf and Hard of Hearing",
                  departmentDescription:
                    "Achieve skills and certifications to teach deaf students in our integrated, 5-year BS/MAT program.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://specialeducation.tcnj.edu/programs/deaf-education/",
                },
                {
                  departmentName: "Elementary Education",
                  departmentDescription:
                    "Develop the skills, knowledge, and foundational experience to be an effective elementary teacher.",
                  departmentUrl: "https://eeced.tcnj.edu/",
                  departmentProgramUrl: "",
                },
                {
                  departmentName: "Middle School Specialization",
                  departmentDescription:
                    "Learn the best practices for middle-level education and gain firsthand experience in the classroom.",
                  departmentUrl: "",
                  departmentProgramUrl: "https://msspec.tcnj.edu/",
                },
                {
                  departmentName: "Secondary Education",
                  departmentDescription:
                    "Gain the knowledge, skills, and experience to excel as a content-area teacher in grades 6–12.",
                  departmentUrl: "https://ease.tcnj.edu/",
                  departmentProgramUrl: "",
                },
                {
                  departmentName: "Special Education",
                  departmentDescription:
                    "Prepare to teach students with diverse disabilities and learning abilities in this accelerated 5-year program.",
                  departmentUrl: "",
                  departmentProgramUrl: "https://specialeducation.tcnj.edu/",
                },
                {
                  departmentName: "Speech Pathology and Audiology",
                  departmentDescription:
                    "Study the skills and strategies to support people with speech, language, and hearing impairments.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://specialeducation.tcnj.edu/programs/speech-pathology/",
                },
                {
                  departmentName: "Urban Education",
                  departmentDescription:
                    "Build the foundation to help students thrive in the urban classroom in this accelerated 5-year BS/MAT program.",
                  departmentUrl: "https://urbaned.tcnj.edu/",
                  departmentProgramUrl: "",
                },
              ],
            },
            {
              name: "Engineering",
              schoolLink: "https://engineering.tcnj.edu/",
              additionalInfo:
                "<a style='text-decoration:underline' href='https://engineering.tcnj.edu/careers-networking/careers-outcomes/'>Careers and Outcomes</a>",
              description:
                "Be part of this high-achieving group of diverse, creative thinkers and problem-solvers with the drive, passion, and intellect to revolutionize the world around us.",
              departments: [
                {
                  departmentName: "Biomedical Engineering",
                  departmentDescription:
                    "Design and develop instruments, devices, and computational models to evolve modern health care.",
                  departmentUrl: "https://biomedicalengineering.tcnj.edu/",
                  departmentProgramUrl: "",
                },
                {
                  departmentName: "Civil Engineering",
                  departmentDescription:
                    "Plan, design, and analyze bridges, roads, dams, buildings, and systems to improve infrastructure.",
                  departmentUrl: "https://civilengineering.tcnj.edu/",
                  departmentProgramUrl: "",
                },
                {
                  departmentName:
                    "Combined Engineering Seven-year Medical Program",
                  departmentDescription:
                    "Earn your BS and MD with three years in engineering plus four years at the New Jersey Medical School.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://engineering.tcnj.edu/academic-programs/bs-md/",
                },
                {
                  departmentName: "Electrical and Computer Engineering",
                  departmentDescription:
                    "Master skills for control systems, communications, instrumentation, or digital signal processing.",
                  departmentUrl:
                    "https://electrical-computerengineering.tcnj.edu/",
                  departmentProgramUrl: "",
                },
                {
                  departmentName: "Engineering Science",
                  departmentDescription:
                    "Gain the engineering knowledge and skills for an engineering career, law school, or graduate school.",
                  departmentUrl: "https://engineeringscience.tcnj.edu/",
                  departmentProgramUrl: "",
                },
                {
                  departmentName: "Integrative STEM Education",
                  departmentDescription:
                    "Gain PreK—8 teaching skills in STEM in this dual-major program with the School of Education.",
                  departmentUrl: "https://istem.tcnj.edu/",
                  departmentProgramUrl: "",
                },
                {
                  departmentName: "Mechanical Engineering",
                  departmentDescription:
                    "Gain a foundation in energy and mechanics to design machines that power communities.",
                  departmentUrl: "https://mechanicalengineering.tcnj.edu/",
                  departmentProgramUrl: "",
                },
                {
                  departmentName: "Technology/Pre-Engineering Education",
                  departmentDescription:
                    "Get certified to teach K—12 technology and engineering design, inspiring a new generation.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://istem.tcnj.edu/about/academic-programs/bachelor-of-science-bs-in-technology-education/",
                },
                {
                  departmentName: "Undeclared–School of Engineering",
                  departmentDescription:
                    "Follow your interests to explore and define your future engineering specialty in your first year.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://engineering.tcnj.edu/academic-programs/undeclared/",
                },
              ],
            },
            {
              name: "Humanities and Social Sciences",
              schoolLink: "https://hss.tcnj.edu",
              description:
                "Join a vibrant community of learners who share a passion for evolving the human story and shaping an equitable, just world.",
              additionalInfo:
                "<a style='text-decoration:underline; margin-bottom: 20px; display: block;' href='https://hss.tcnj.edu/academics/teacher-education-programs/'>Teacher Education Programs</a><a style='text-decoration:underline' href='https://hss.tcnj.edu/interdisciplinary-programs/healthcare-careers/'>Health Care Careers</a>",
              departments: [
                {
                  departmentName: "African American Studies",
                  departmentDescription:
                    "Study issues of race and ethnicity and gain a deep understanding of culture in its many contexts.",
                  departmentUrl: "https://african-americanstudies.tcnj.edu/",
                  departmentProgramUrl: "",
                },
                {
                  departmentName: "Criminology",
                  departmentDescription:
                    "Study crime, its social contexts, and related analysis to learn how it impacts policy and practice.",
                  departmentUrl: "https://criminology.tcnj.edu/",
                  departmentProgramUrl: "",
                },
                {
                  departmentName: "English",
                  departmentDescription:
                    "Learn to think creatively, read critically, and turn a passion for words into your future career.",
                  departmentUrl: "https://english.tcnj.edu/",
                  departmentProgramUrl: "",
                },
                {
                  departmentName: "History",
                  departmentDescription:
                    "Discover how researching the past can give you diverse skills for a variety of career options.",
                  departmentUrl: "https://history.tcnj.edu/",
                  departmentProgramUrl: "",
                },
                {
                  departmentName: "International Studies",
                  departmentDescription:
                    "Study global affairs in an interdisciplinary program focused on foreign languages and study abroad.",
                  departmentUrl: "https://internationalstudies.tcnj.edu/",
                  departmentProgramUrl: "",
                },
                {
                  departmentName: "Philosophy, Religion, and Classical Studies",
                  departmentDescription:
                    "Critically examine theories of reality, knowledge, ethics, religion, and classics via analytical methods.",
                  departmentUrl: "https://philos.tcnj.edu/",
                  departmentProgramUrl: "",
                },
                {
                  departmentName: "Political Science",
                  departmentDescription:
                    "Study political theories and institutions while honing skills in research, analysis, and argument.",
                  departmentUrl: "https://polisci.tcnj.edu/",
                  departmentProgramUrl: "",
                },
                {
                  departmentName: "Psychology",
                  departmentDescription:
                    "Explore questions of human thought, emotion, and behavior, and learn how to help empower people.",
                  departmentUrl: "https://psychology.tcnj.edu/",
                  departmentProgramUrl: "",
                },
                {
                  departmentName: "Public Health",
                  departmentDescription:
                    "Pursue an accelerated bachelor’s to master’s degree.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://graduate.tcnj.edu/explore-programs/mph-accelerated/",
                },
                {
                  departmentName: "Public Policy",
                  departmentDescription:
                    "Pursue an accelerated bachelor’s to master’s degree.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://graduate.tcnj.edu/explore-programs/mpp-accelerated",
                },
                {
                  departmentName: "Sociology and Anthropology",
                  departmentDescription:
                    "Study social life and the human condition to help inform real solutions for complex societal problems.",
                  departmentUrl: "https://socanth.tcnj.edu/",
                  departmentProgramUrl: "",
                },
                {
                  departmentName: "Teacher Education Programs",
                  departmentDescription:
                    "Double major or pursue an education degree focused on a specific discipline.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://hss.tcnj.edu/academics/teacher-education-programs/",
                },
                {
                  departmentName:
                    "Undeclared–School of Humanities and Social Sciences",
                  departmentDescription:
                    "Explore interests in HSS and social justice to narrow your focus for future studies.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://hss.tcnj.edu/interdisciplinary-programs/undeclared/",
                },
                {
                  departmentName: "Women’s, Gender, and Sexuality Studies",
                  departmentDescription:
                    "Become empowered with self-knowledge and the skills to lead for social justice.",
                  departmentUrl: "https://wgs.tcnj.edu/",
                  departmentProgramUrl: "",
                },
                {
                  departmentName: "World Language and Cultures",
                  departmentDescription:
                    "Gain proficiency in a world language through the study of culture, linguistics, and literature.",
                  departmentUrl: "https://wlc.tcnj.edu/",
                  departmentProgramUrl: "",
                },
              ],
            },
            {
              name: "Nursing/Health",
              schoolLink: "https://nhs.tcnj.edu/",
              additionalInfo:
                "<a style='text-decoration:underline' href='https://nhs.tcnj.edu/academics/careers-outcomes/'>Careers and Outcomes</a>",
              description:
                "Health care today increasingly emphasizes health promotion over disease management, a shift aligning perfectly with our focus on wellness, healthy lifestyles, and physical fitness.",
              departments: [
                {
                  departmentName: "Kinesiology and Health Sciences",
                  departmentDescription:
                    "Enter the field of health and fitness or pursue advanced study in physical therapy or related professions.",
                  departmentUrl: "https://khs.tcnj.edu/",
                  departmentProgramUrl: "",
                },
                {
                  departmentName: "Nursing",
                  departmentDescription:
                    "Gain clinical and critical thinking skills in a traditional 4-year program or an accelerated program leading to a BSN.",
                  departmentUrl: "https://nursing.tcnj.edu/",
                  departmentProgramUrl: "",
                },
                {
                  departmentName: "Physical Therapy Dual Degree",
                  departmentDescription:
                    "Combine a kinesiology and health sciences degree with advanced study in physical therapy.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://khs.tcnj.edu/academic-programs/khs-and-physical-therapy-doctoral-program/",
                },
                {
                  departmentName: "Public Health",
                  departmentDescription:
                    "Focus on health communication or social and behavioral science. Option to pursue an accelerated bachelor's to master's degree.",
                  departmentUrl: "https://publichealth.tcnj.edu/",
                  departmentProgramUrl: "",
                },
              ],
            },
            {
              name: "Science",
              schoolLink: "https://science.tcnj.edu/",
              additionalInfo:
                "<a style='text-decoration:underline' href='https://science.tcnj.edu/academics/teacher-education-programs/'>Teacher Education Programs</a>",
              description:
                "Join this driven group of creative thinkers and grapple with the big problems and bold possibilities of science. ",
              departments: [
                {
                  departmentName: "Biology",
                  departmentDescription:
                    "Uncover life’s secrets in single cells and across ecosystems in a research-intensive program.",
                  departmentUrl: "https://biology.tcnj.edu/",
                  departmentProgramUrl: "",
                },
                {
                  departmentName: "Chemistry",
                  departmentDescription:
                    "Develop theoretical knowledge and practical skills using state-of-the-art lab instrumentation.",
                  departmentUrl: "https://chemistry.tcnj.edu/",
                  departmentProgramUrl: "",
                },
                {
                  departmentName: "Computer Science",
                  departmentDescription:
                    "Delve into exciting fields and collaborate to find computational solutions for complex problems.",
                  departmentUrl: "https://computerscience.tcnj.edu/",
                  departmentProgramUrl: "",
                },
                {
                  departmentName: "Dual Degrees",
                  departmentDescription:
                    "Combine a science degree with advanced study in medicine, optometry, pharmacy, or physical therapy.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://science.tcnj.edu/academics/areas-of-study/dual-degree-programs/",
                },
                {
                  departmentName: "Mathematics and Statistics",
                  departmentDescription:
                    "Study patterns in numbers, shapes, and data to understand abstract structures and solve problems.",
                  departmentUrl: "https://mathstat.tcnj.edu/",
                  departmentProgramUrl: "",
                },
                {
                  departmentName: "Physics",
                  departmentDescription:
                    "Unravel the laws and forces of the universe, building on fundamentals to focus on your passion.",
                  departmentUrl: "https://physics.tcnj.edu/",
                  departmentProgramUrl: "",
                },
              ],
            },
          ],
        },
        {
          title: "Graduate",

          programList: [
            {
              name: "Business",
              schoolLink: "https://graduate.tcnj.edu",
              additionalInfo:
                "<a style='text-decoration:underline' href='https://graduate.tcnj.edu/'>Graduate Studies</a>",
              description:
                "Get equipped for success in a community of innovators that prioritizes discovery, agility, analysis, and imagination. ",
              // additionalInfo: "",
              departments: [
                {
                  departmentName: "Business Administration, MBA",
                  departmentDescription:
                    "TCNJ's MBA program prepares early-career professionals for excellence in today’s global economy.",
                  departmentUrl: "",
                  departmentProgramUrl: "https://mba.tcnj.edu/",
                },
                {
                  departmentName:
                    "Business Administration and Public Health, MBA/MPH",
                  departmentDescription:
                    "Designed for professionals seeking to move forward in a managerial role in public health.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://graduate.tcnj.edu/explore-programs/dual-mba-mph/",
                },
                {
                  departmentName: "Business Analytics Certificate",
                  departmentDescription:
                    "Build contemporary analytic skills for complex business decision-making.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://graduate.tcnj.edu/explore-programs/business-analytics/",
                },
                {
                  departmentName:
                    "Strategy, Innovation, and Leadership Certificate",
                  departmentDescription:
                    "Designed to create leaders of change.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://graduate.tcnj.edu/explore-programs/strategy-innovation-leadership/",
                },
              ],
            },

            {
              name: "Counseling",
              schoolLink: "https://counselored.tcnj.edu/",
              additionalInfo:
                "<a style='text-decoration:underline' href='https://graduate.tcnj.edu/'>Graduate Studies</a>",
              description:
                "Prepare to counsel diverse populations in a variety of settings.",
              departments: [
                {
                  departmentName: "Clinical Mental Health Counseling, MA",
                  departmentDescription:
                    "Options for generalist practice or addiction counseling.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://graduate.tcnj.edu/explore-programs/clinical-mental-health/",
                },
                {
                  departmentName: "School Counseling, MA",
                  departmentDescription:
                    "Designed to qualify eligible students for state certification in school counseling at the elementary and secondary levels.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://graduate.tcnj.edu/explore-programs/school-counseling/",
                },
                {
                  departmentName: "Student Assistance Coordinator Certificate",
                  departmentDescription:
                    "Provide in-school assessment, crisis intervention and counseling, and referral services to any student experiencing personal, family, or peer difficulties.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://graduate.tcnj.edu/explore-programs/sac-program/",
                },
              ],
            },
            {
              name: "Education",
              schoolLink: "https://education.tcnj.edu/graduate/",
              additionalInfo:
                "<a style='text-decoration:underline' href='https://graduate.tcnj.edu/'>Graduate Studies</a>",
              description:
                "Equipping teachers and administrators for success as educators and capable researchers.",
              departments: [
                {
                  departmentName: "Early Childhood and Elementary Education",
                  departmentDescription:
                    "Gain the training, education, and fieldwork experience to instruct young learners.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://graduate.tcnj.edu/explore-programs/ecee-dual-cert/",
                },
                {
                  departmentName:
                    "Educational Leadership — Principal, Supervisor, Teacher Leader",
                  departmentDescription:
                    "Understand and successfully confront the multiple educational challenges posed by 21st-century conditions and contexts.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://graduate.tcnj.edu/explore-programs/ed-leadership/",
                },
                {
                  departmentName: "Educational Leadership — Instruction (RTC)",
                  departmentDescription:
                    "Enhance your instructional practices and develop classroom-based reflection and inquiry.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://graduate.tcnj.edu/explore-programs/instruction/",
                },
                {
                  departmentName: "Educational Studies",
                  departmentDescription:
                    "Design a degree tailored to your interests and preferred learning style. Choose two certificates and add a capstone to create a specialized master’s degree.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://graduate.tcnj.edu/explore-programs/educational-studies/",
                },
                {
                  departmentName: "Teaching English as a Second Language",
                  departmentDescription:
                    "Build the right foundation to work with learners from diverse linguistic, educational, and ethnic backgrounds.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://graduate.tcnj.edu/explore-programs/tesl-med/",
                },
                {
                  departmentName: "Special Education",
                  departmentDescription:
                    "Prepare to teach students with diverse disabilities and learning abilities.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://graduate.tcnj.edu/explore-programs/special-education/",
                },
              ],
            },
            {
              name: "English",
              schoolLink:
                "https://hss.tcnj.edu/academics/graduate-areas-of-study/",
              additionalInfo:
                "<a style='text-decoration:underline' href='https://graduate.tcnj.edu/'>Graduate Studies</a>",
              description:
                "Gain a competitive edge in your career field or to lay the groundwork for further graduate study.",
              departments: [
                {
                  departmentName: "English, MA",
                  departmentDescription:
                    "Pursue advanced study of literatures in English in a program that provides flexibility for working adults who already hold a bachelor’s degree.",
                  departmentUrl: "",
                  departmentProgramUrl: "https://englishgrad.tcnj.edu/",
                },
                {
                  departmentName: "Gender and Sexuality Studies Certificate",
                  departmentDescription:
                    "Explore contemporary issues and current scholarship in the field of gender studies in this 5-course advanced study program.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://graduate.tcnj.edu/explore-programs/gender-cert/",
                },
              ],
            },
            {
              name: "Integrative STEM",
              schoolLink:
                "https://istem.tcnj.edu/master-of-education-in-integrative-stem-top/",
              additionalInfo:
                "<a style='text-decoration:underline' href='https://graduate.tcnj.edu/'>Graduate Studies</a>",
              description:
                "Focus on engineering and technology design and engage in a variety of STEM content areas and educational methods.",
              departments: [
                {
                  departmentName: "English, MA",
                  departmentDescription:
                    "Pursue advanced study of literatures in English in a program that provides flexibility for working adults who already hold a bachelor’s degree.",
                  departmentUrl: "",
                  departmentProgramUrl: "https://englishgrad.tcnj.edu/",
                },
                {
                  departmentName: "Gender and Sexuality Studies Certificate",
                  departmentDescription:
                    "Explore contemporary issues and current scholarship in the field of gender studies in this 5-course advanced study program.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://graduate.tcnj.edu/explore-programs/gender-cert/",
                },
              ],
            },
            {
              name: "Nursing",
              schoolLink:
                "https://nursinghes.tcnj.edu/academics/graduate-areas-of-study/",
              additionalInfo:
                "<a style='text-decoration:underline' href='https://graduate.tcnj.edu/'>Graduate Studies</a>",
              description:
                "Make bold strides in our programs in nursing and public health. You will be challenged to develop the advanced skills and knowledge expected of leaders in their professions.",
              departments: [
                {
                  departmentName:
                    "Adult-Gerontology Primary Care Nurse Practitioner",
                  departmentDescription:
                    "Develop the expertise to provide primary care to individuals ranging from young adults to the elderly.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://graduate.tcnj.edu/explore-programs/agnpcnp/",
                },
                {
                  departmentName: "Family Nurse Practitioner",
                  departmentDescription:
                    "Through a combination of courses and clinical experiences, you’ll learn to expertly develop health interventions and evaluate health care outcomes for individuals across the lifespan — from infants to the elderly.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://graduate.tcnj.edu/explore-programs/fnp/",
                },
                {
                  departmentName: "Neonatal Nurse Practitioner",
                  departmentDescription:
                    "Learn to care for low- and high-risk newborns, infants, toddlers, and their families in diverse hospital and clinic settings. This program is a partnership between TCNJ and Thomas Jefferson University in Philadelphia.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://graduate.tcnj.edu/explore-programs/nnp/",
                },
                {
                  departmentName: "Nursing Educator Certificate",
                  departmentDescription:
                    "Acquire advanced knowledge in the nursing field with a special focus on teaching in an academic environment and/or health care setting.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://graduate.tcnj.edu/explore-programs/nurse-educator-certificate/",
                },
                {
                  departmentName: "School Nurse Instructional Certificate",
                  departmentDescription:
                    "Prepare to perform nursing services and to teach in areas related to health in public schools serving students in preschool through 12th grade.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://graduate.tcnj.edu/explore-programs/school-nurse-instruct-cert/",
                },
              ],
            },
            {
              name: "Public Health",
              schoolLink:
                "https://nursinghes.tcnj.edu/academics/graduate-areas-of-study/",
              additionalInfo:
                "<a style='text-decoration:underline' href='https://graduate.tcnj.edu/'>Graduate Studies</a>",
              description:
                "Public health advocates enter a varied and growing range of professions — from medicine to marketing, from epidemiology to public policy — and lead change in human health.",
              departments: [
                {
                  departmentName:
                    "Business Administration and Public Health, MBA/MPH",
                  departmentDescription:
                    "This 2-year joint MBA/MPH program combines an MBA specializing in business analytics with an MPH focusing on either epidemiology and health analytics or health communication.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://graduate.tcnj.edu/explore-programs/dual-mba-mph/",
                },
                {
                  departmentName: "Health and Risk Communication Certificate",
                  departmentDescription:
                    "This fully online certificate provides you with the tools and knowledge to effectively promote healthy behavior to target communities.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://graduate.tcnj.edu/explore-programs/health-risk-communication/",
                },
                {
                  departmentName: "Public Health, MPH",
                  departmentDescription:
                    "This 2-year comprehensive interdisciplinary Master of Public Health program provides you with the knowledge and skills to protect and improve family and community health.",
                  departmentUrl: "",
                  departmentProgramUrl: "https://mph.tcnj.edu/",
                },
                {
                  departmentName: "Public Health Certificate",
                  departmentDescription:
                    "This program is for school and community health nurses, other health professionals, and health care managers to retool their skills for the new era in healthcare delivery.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://graduate.tcnj.edu/explore-programs/public-health-certificate/",
                },
              ],
            },
            {
              name: "Public Policy",
              schoolLink: "https://graduate.tcnj.edu/",
              additionalInfo:
                "<a style='text-decoration:underline' href='https://graduate.tcnj.edu/publicpolicy/'>Graduate Programs</a><br/><a style='text-decoration:underline' href='https://graduate.tcnj.edu/'>Graduate Studies</a>",
              description:
                "Public policy professionals engage in policy research, analysis, management, and program evaluation.",
              departments: [
                {
                  departmentName: "Public Policy, MPP",
                  departmentDescription:
                    "The Master of Public Policy prepares you to engage effectively in policy research, analysis, management, and program evaluation.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://graduate.tcnj.edu/explore-programs/mpp/",
                },
                {
                  departmentName: "Public Policy Certificate",
                  departmentDescription:
                    "This certificate is for professionals, recent graduates, and TCNJ seniors who want specialized training in policy analysis and policymaking.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://graduate.tcnj.edu/explore-programs/public-policy-cert/",
                },
              ],
            },
          ],
        },
        {
          title: "Certificate Programs",
          programList: [
            {
              name: "Business",
              schoolLink: "https://graduate.tcnj.edu",
              additionalInfo:
                "<a style='text-decoration:underline' href='https://graduate.tcnj.edu/'>Graduate Studies</a>",
              description:
                "Get equipped for success in a community of innovators that prioritizes discovery, agility, analysis, and imagination. ",
              departments: [
                {
                  departmentName: "Business Analytics Certificate",
                  departmentDescription:
                    "Build contemporary analytic skills for complex business decision-making.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://graduate.tcnj.edu/explore-programs/business-analytics/",
                },
                {
                  departmentName:
                    "Strategy, Innovation, and Leadership Certificate",
                  departmentDescription:
                    "Designed to create leaders of change.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://graduate.tcnj.edu/explore-programs/strategy-innovation-leadership/",
                },
              ],
            },
            {
              name: "Counseling",
              schoolLink: "https://counselored.tcnj.edu/",
              additionalInfo:
                "<a style='text-decoration:underline' href='https://graduate.tcnj.edu/'>Graduate Studies</a>",
              description:
                "Prepare to counsel diverse populations in a variety of settings.",
              departments: [
                {
                  departmentName: "Student Assistance Coordinator Certificate",
                  departmentDescription:
                    "Provide in-school assessment, crisis intervention and counseling, and referral services to any student experiencing personal, family, or peer difficulties.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://graduate.tcnj.edu/explore-programs/sac-program/",
                },
              ],
            },
            {
              name: "Education",
              schoolLink:
                "https://education.tcnj.edu/academics/graduate-areas-of-study/",
              additionalInfo:
                "<a style='text-decoration:underline' href='https://graduate.tcnj.edu/'>Graduate Studies</a>",
              description:
                "Equipping teachers and administrators for success as educators and capable researchers.",
              departments: [
                {
                  departmentName:
                    "Environmental Sustainability Education Certificate",
                  departmentDescription:
                    "Discover the interconnectedness of sustainability efforts across disciplines and fields.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://graduate.tcnj.edu/explore-programs/env-sustainability-cert/",
                },
                {
                  departmentName: "Supervisor Certificate",
                  departmentDescription:
                    "The Supervisor Certificate Program prepares teachers who wish to step into supervisory roles and obtain a NJ Supervisor Standard Administrative Certificate.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://graduate.tcnj.edu/explore-programs/supervisor-certificate/",
                },
                {
                  departmentName: "Teacher Leader Certificate",
                  departmentDescription:
                    "The Teacher Leader Certificate Program prepares teachers for non-supervisory leadership roles, such as facilitator, coach, and professional developer.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://graduate.tcnj.edu/explore-programs/tl-certificate/",
                },
                {
                  departmentName:
                    "Teacher of Students with Disabilities Certificate",
                  departmentDescription:
                    "This certificate program is specifically designed for students who already hold a standard teaching certificate in a field other than special education and wish to obtain New Jersey certification as a Teacher of Students with Disabilities.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://graduate.tcnj.edu/explore-programs/teacher-of-students-with-disabilites/",
                },
                {
                  departmentName:
                    "Teaching English as a Second Language Initial Certification",
                  departmentDescription:
                    "This certificate program is specifically designed for students without their teaching licensure.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://graduate.tcnj.edu/explore-programs/tesl-initial-cert/",
                },
              ],
            },
            {
              name: "Gender Studies",
              schoolLink:
                "https://wgs.tcnj.edu/curriculum/graduate-certificate/",
              additionalInfo:
                "<a style='text-decoration:underline' href='https://graduate.tcnj.edu/'>Graduate Studies</a>",
              description:
                "Connect with an inclusive, dynamic learning community where students are empowered as agents of social change. We study — and question — how gender and sexuality intersect with race, class, and other identities to mold ourselves and our social structures.",
              departments: [
                {
                  departmentName: "Gender and Sexuality Studies Certificate",
                  departmentDescription:
                    "Explore contemporary issues and current scholarship in the field of gender studies in this 5-course advanced study program.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://graduate.tcnj.edu/explore-programs/gender-cert/",
                },
              ],
            },
            {
              name: "Nursing",
              schoolLink:
                "https://wgs.tcnj.edu/curriculum/graduate-certificate/",
              additionalInfo:
                "<a style='text-decoration:underline' href='https://graduate.tcnj.edu/'>Graduate Studies</a>",
              description:
                "Develop the advanced skills and knowledge expected of leaders in your profession.",
              departments: [
                {
                  departmentName: "Nursing Educator Certificate",
                  departmentDescription:
                    "Acquire advanced knowledge in the nursing field with a special focus on teaching in an academic environment and/or health care setting.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://graduate.tcnj.edu/explore-programs/nurse-educator-certificate/",
                },
                {
                  departmentName: "School Nurse Instructional Certificate",
                  departmentDescription:
                    "Prepare to perform nursing services and to teach in areas related to health in public schools serving students in preschool through 12th grade.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://graduate.tcnj.edu/explore-programs/school-nurse-instruct-cert/",
                },
              ],
            },
            {
              name: "Public Health",
              schoolLink:
                "https://wgs.tcnj.edu/curriculum/graduate-certificate/",
              additionalInfo:
                "<a style='text-decoration:underline' href='https://graduate.tcnj.edu/'>Graduate Studies</a>",
              description:
                "Be an advocate in a varied and growing range of professions — from medicine to marketing, from epidemiology to public policy — and lead change in human health.",
              departments: [
                {
                  departmentName: "Health and Risk Communication Certificate",
                  departmentDescription:
                    "This fully online certificate provides you with the tools and knowledge to effectively promote healthy behavior to target communities.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://graduate.tcnj.edu/explore-programs/health-risk-communication/",
                },
                {
                  departmentName: "Public Health Certificate",
                  departmentDescription:
                    "This program is for school and community health nurses, other health professionals, and health care managers to retool their skills for the new era in healthcare delivery.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://graduate.tcnj.edu/explore-programs/public-health-certificate/",
                },
                {
                  departmentName: "Wellness Coaching Certificate",
                  departmentDescription:
                    "Optimize overall health and well-being through education, health promotion, coach instruction, and practical integrated experiences.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://graduate.tcnj.edu/explore-programs/wellness-cert/",
                },
              ],
            },
            {
              name: "Public Policy",
              schoolLink: "",
              additionalInfo:
                "<a style='text-decoration:underline' href='https://graduate.tcnj.edu/'>Graduate Studies</a>",
              description:
                "Public policy professionals engage in policy research, analysis, management, and program evaluation.",
              departments: [
                {
                  departmentName: "Public Policy Certificate",
                  departmentDescription:
                    "This certificate is for professionals, recent graduates, and TCNJ seniors who want specialized training in policy analysis and policymaking.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://graduate.tcnj.edu/explore-programs/public-policy-cert/",
                },
              ],
            },
            {
              name: "Professional Writing",
              schoolLink: "",
              additionalInfo:
                "<a style='text-decoration:underline' href='https://graduate.tcnj.edu/'>Graduate Studies</a>",
              description:
                "Design and publish content across media platforms tailored to specific audience needs and organizational objectives.",
              departments: [
                {
                  departmentName: "Professional and UX/UI Writing Certificate",
                  departmentDescription:
                    "This certificate is designed for seniors, recent graduates and professionals seeking enhanced skills and credentials in such applied communications fields as technical writing, user experience research, social media marketing, public relations, and online community management.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://graduate.tcnj.edu/explore-programs/professional-writing/",
                },
              ],
            },
          ],
        },
        {
          title: "Dual Degrees",
          programList: [
            {
              name: "Business and Public Health",
              schoolLink: "https://graduate.tcnj.edu/mba-mph/",
              description: "",
              departments: [
                {
                  departmentName: "Business Administration and Public Health",
                  departmentDescription:
                    "This 2-year joint MBA/MPH program combines an MBA specializing in business analytics with an MPH focusing on either epidemiology and health analytics or health communication.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://graduate.tcnj.edu/explore-programs/dual-mba-mph/",
                },
              ],
            },
            {
              name: "Education",
              schoolLink: "https://education.tcnj.edu/",
              description: "",
              departments: [
                {
                  departmentName: "Early Childhood and Elementary Education",
                  departmentDescription:
                    "Gain the training, education, and fieldwork experience to instruct young learners.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://graduate.tcnj.edu/explore-programs/ecee-dual-cert/",
                },
                {
                  departmentName: "Five-Year Education Programs",
                  departmentDescription:
                    "Teachers with master’s degrees enjoy a competitive edge: greater demand, higher salaries, and more paths to advancement.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://academics.tcnj.edu/undergraduate-study/?program=teacher-education",
                },
              ],
            },
            {
              name: "Law",
              schoolLink: "https://hss.tcnj.edu/dual-degree-bachelors-jd/",
              additionalInfo:
                "<a style='text-decoration:underline' href='https://academics.tcnj.edu/undergraduate-study/?program=dual-degree'>Dual Degree Programs</a>",
              description:
                "Law program offered in conjunction with Villanova University’s Charles Widger School of Law. ",
              departments: [
                {
                  departmentName: "Bachelor’s/Juris Doctor (JD)",
                  departmentDescription:
                    "Third-year juniors and graduating seniors can gain direct entry to law school.",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://hss.tcnj.edu/dual-degree-bachelors-jd/",
                },
              ],
            },

            {
              name: "Medical or Optometry",
              description:
                "Medical programs offered in conjunction with Rutgers New Jersey Medical School (NJMS). Optometry program offered in conjunction with SUNY College of Optometry.",
              departments: [
                {
                  departmentName: "Biology, BS/MD",
                  departmentDescription: "",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://biology.tcnj.edu/academics/medical-careers/7-year-medical-program/",
                },
                {
                  departmentName: "Chemistry, BS/MD",
                  departmentDescription: "",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://chemistry.tcnj.edu/curriculum/bs-mdprogram/",
                },
                {
                  departmentName: "English, BS/MD",
                  departmentDescription: "",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://biology.tcnj.edu/academics/medical-careers/7-year-medical-program/",
                },
                {
                  departmentName: "Mathematics, BS/MD",
                  departmentDescription: "",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://mathstat.tcnj.edu/7-year-medicalmathematics-major/",
                },
                {
                  departmentName: "Spanish, BA/MD",
                  departmentDescription: "",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://biology.tcnj.edu/academics/medical-careers/7-year-medical-program/",
                },
                {
                  departmentName: "Biology, BS/OD",
                  departmentDescription: "",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://biology.tcnj.edu/academics/medical-careers/7-year-optometry-program/",
                },
                {
                  departmentName: "Computer Science, BS/MD",
                  departmentDescription: "",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://computerscience.tcnj.edu/cs-programs-research/requirements-for-7-year-medical-program/",
                },
                {
                  departmentName: "Engineering Science, BSES/MD",
                  departmentDescription: "",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://engineering.tcnj.edu/academic-programs/bs-md/",
                },
                {
                  departmentName: "Philosophy, BS/MD",
                  departmentDescription: "",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://biology.tcnj.edu/academics/medical-careers/7-year-medical-program/",
                },
                {
                  departmentName: "Biomedical Engineering, BSBME/MD",
                  departmentDescription: "",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://engineering.tcnj.edu/academic-programs/bs-md/",
                },
                {
                  departmentName: "Economics, BA/MD",
                  departmentDescription: "",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://business.tcnj.edu/academics/seven-year-medical-program/",
                },
                {
                  departmentName: "History, BS/MD",
                  departmentDescription: "",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://history.tcnj.edu/academic-programs/academic-programs/",
                },
                {
                  departmentName: "Physics, BS/MD",
                  departmentDescription: "",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://physics.tcnj.edu/academics/programs/",
                },
              ],
            },
            {
              name: "Pharmacy",

              description:
                "Pharmacy programs are offered in conjunction with the Jefferson College of Pharmacy of Thomas Jefferson University. ",
              departments: [
                {
                  departmentName: "Biology, BS/PharmD",
                  departmentDescription: "",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://biology.tcnj.edu/biology-and-pharmacy-doctoral-program/",
                },
                {
                  departmentName: "Chemistry, BS/PharmD",
                  departmentDescription: "",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://chemistry.tcnj.edu/chemistry-and-pharmacy-doctoral-program/",
                },
              ],
            },
            {
              name: "Physical Therapy",

              description:
                "Physical therapy programs are offered in conjunction with the Jefferson College of Rehabilitation Sciences of Thomas Jefferson University.",
              departments: [
                {
                  departmentName: "Biology, BS/DPT",
                  departmentDescription: "",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://biology.tcnj.edu/academics/biology-and-physical-therapy-doctoral-program/",
                },
                {
                  departmentName: "Kinesiology and Health Sciences, BS/DPT",
                  departmentDescription: "",
                  departmentUrl: "",
                  departmentProgramUrl:
                    "https://khs.tcnj.edu/khs-and-physical-therapy-doctoral-program/",
                },
              ],
            },
          ],
        },
      ],
    };
  },
  methods: {
    nextStep(nextLetter) {
      this.currentStep = nextLetter;
    },
    nextProgramStep(nextProgramLetter) {
      this.programStep = nextProgramLetter;
    },
    nextProgramDescription(nextProgramDesciption) {
      this.programDescription = nextProgramDesciption;
    },
    nextProgramLink(programLinkUrl) {
      this.programLink = programLinkUrl;
    },
    nextIndividualLink(number) {
      this.individualStep = number;
    },
    nextLearnMoreSection(info) {
      this.additionalInfo = info;
    },
    programClear() {
      this.programStep = "";
    },
    decodeHtml(html) {
      console.log(html);
      var txt = document.createElement("textarea");
      txt.innerHTML = html;
      return txt.value;
    },
  },
};
</script>
